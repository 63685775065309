'use client' // this is a client component 👈🏽
import type { IInput } from './SelectGhost.props'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import SelectMui, { SelectChangeEvent } from '@mui/material/Select'

import {
  ErrorMsg,
  SelectFieldContainer,
  SelectStyled,
  SelectFieldWrapper,
  InputLabelStyled,
} from './SelectGhost.styled'
import React, { useEffect } from 'react'

export const SelectGhost = ({
  onChangeHandler,
  options,
  defaultSelected,
  placeholder,
  valueSelected,
  disabled = false,
}: any) => {
  const [value, setValue] = React.useState('')

  useEffect(() => {
    defaultSelected && setValue(defaultSelected)
  }, [defaultSelected])

  const [isValue, setIsValue] = React.useState(false)
  const [focused, setFocused] = React.useState(false)

  // useEffect(() => {
  //   valueSelected && setValue(valueSelected as string)
  // }, [valueSelected])
  // useEffect(() => {
  //   value && onChangeHandler(value)
  // }, [value])

  useEffect(() => {
    valueSelected && setValue(valueSelected as string)
    value && onChangeHandler(value)
  }, [])

  const handleFocus = () => {
    setFocused(true)
  }

  const handleBlur = () => {
    setFocused(false)
  }

  return (
    <SelectFieldWrapper>
      {/* <SelectFieldContainer error={error}> */}
      <SelectFieldContainer isValue={isValue} focused={focused}>
        {/* <button onClick={() => setValue('jjo')}>ok</button> */}
        <FormControl fullWidth>
          {/* <InputLabelStyled id='demo-simple-select-label'>{placeholder}</InputLabelStyled> */}
          <SelectStyled
            disabled={disabled}
            focused={focused}
            placeholder={'placeholder'}
            // labelId='demo-simple-select-label'
            // id='demo-simple-select'
            value={value}
            label='value'
            onChange={(event: any) => {
              setIsValue(true)
              setValue(event.target.value as string)
              onChangeHandler(event.target.value)
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            // sx={{ "&:focus-within + label": { color: "pink" } }} // change the color as desired
          >
            {options.map((option: any) => (
              <MenuItem key={option.key} value={option.value}>
                {option.title}
              </MenuItem>
            ))}
            {/* <MenuItem value={10}>Ten</MenuItem> */}
          </SelectStyled>
        </FormControl>
      </SelectFieldContainer>
    </SelectFieldWrapper>
  )
}
