import type { IMenuItem } from './Layout.props'
import { ReactComponent as NewsSvg } from './img/news.svg'
import { ReactComponent as ContactsSvg } from './img/contacts.svg'
import { ReactComponent as IpuSvg } from './img/ipu.svg'
import { ReactComponent as ObjectsSvg } from './img/object.svg'
import { ReactComponent as PeopleSvg } from './img/people.svg'
import { ReactComponent as OrdersSvg } from './img/orders.svg'
import { ReactComponent as BillsSvg } from './img/bills.svg'
import { ReactComponent as ChatSvg } from './img/chat.svg'
import { ReactComponent as DashboardSvg } from './img/dashboard.svg'

export const sidebarMenu: IMenuItem[] = [
  {
    index: 0,
    title: 'Объекты',
    icon: <ObjectsSvg />,
    path: '/objects/complexes',
    active_menu: '/objects',
    // children: [
    //   {
    //     title: 'ЖК',
    //     icon: <ObjectsSvg />,
    //     path: '/objects/complexes',
    //     active_sub_menu: '/objects',
    //   },
    //   {
    //     title: 'Дома',
    //     icon: <ObjectsSvg />,
    //     path: '/objects/buildings',
    //     active_sub_menu: '/objects',
    //   },
    // ]
  },
  {
    index: 1,
    icon: <PeopleSvg />,
    title: 'Люди',
    path: '/people',
    active_menu: '/people',
  },
  {
    index: 2,
    icon: <OrdersSvg />,
    title: 'Заявки',
    path: '/orders',
    active_menu: '/orders',
  },
  // {
  //   index: 2,
  //   icon: <OrdersSvg />,
  //   title: 'Уведомления',
  //   path: '/channels',
  //   active_menu: '/channels',
  // },
  {
    index: 222,
    icon: <ChatSvg />,
    title: 'Чаты',
    path: '/chat',
    active_menu: '/chat',
  },
  {
    index: 3,
    icon: <BillsSvg />,
    title: 'Счета',
    path: '/bills',
    active_menu: '/bills',
  },
  {
    index: 4,
    icon: <IpuSvg />,
    title: 'ИПУ',
    path: '/ipus',
    active_menu: '/ipus',
  },
  {
    index: 5,
    icon: <DashboardSvg />,
    title: 'Дашборды',
    path: '/dashboard',
    active_menu: '/dashboard',
  },
  // {
  //   index: 3,
  //   icon: <NewsSvg />,
  //   title: 'Новости',
  //   path: '/news',
  //   active_menu: '/news',
  // },
  // {
  //   index: 4,
  //   icon: <ContactsSvg />,
  //   title: 'Поддержка',
  //   path: '/support',
  //   active_menu: '/support',
  // },
]
